.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}
.particles-bg {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1000;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
