@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');

body {
  margin: 0;
  font-family: 'nasalization-rg', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  text-shadow: black 0px 0px 2px;
  height: 100vh;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
@font-face {
  font-family: 'nasalization-rg';
  src: local('nasalization-rg'),
    url(./assets/nasalization-rg.ttf) format('truetype');
}
